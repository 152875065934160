import React from 'react';

import { Link } from "react-router-dom";
import Layout from '../Layout';

const TermsConditions = () => {
    return (

        <Layout>


            <section className="abtSecOne">
                <div className="subOne">
                    <h3 className='wow fadeInUp' data-wow-duration="0.8s"></h3>
                    <h3 className='posTwo wow fadeInUp' data-wow-duration="1.2s">Privacy Policy.</h3>
                </div>
                <div className="subTwo">
                    <div>
                        <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">Effective Date: December 12 2024</p>
                    </div>

                </div>

            </section>


            <section className="abtSecTwo">

                <div className='secTwoDivOne'>
                    <span></span>
                </div>
                <div className='secTwoDivTwo pr-5'>

                    <div>
                        <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">Entero Homes is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our mobile application ("Entero Homes") designed for marketers to manage transactions and request commissions.</p>

                        <div className='policy-section'>
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">1. Information We Collect</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">When you use our App, we may collect the following information:</p>

                            <div className='policy-section-sub'>
                                <p className="policyHeadSub wow fadeInUp" data-wow-duration="1.2s">A. Personal Information</p>
                                <ul>
                                    <li>Full Name</li>
                                    <li>Email Address</li>
                                    <li>Phone Number</li>
                                    <li>Bank Account Details (for commission payments)</li>
                                    <li>Profile Picture</li>
                                </ul>
                            </div>

                            <div className='policy-section-sub'>
                                <p className="policyHeadSub wow fadeInUp" data-wow-duration="1.2s">B. Transaction & Commission Data</p>
                                <ul>
                                    <li>Sales and referral transactions</li>
                                    <li>Commission requests and payment history</li>
                                </ul>
                            </div>

                            <div className='policy-section-sub'>
                                <p className="policyHeadSub wow fadeInUp" data-wow-duration="1.2s">C. Device & Usage Information</p>
                                <ul>
                                    <li>IP address</li>
                                    <li>Device information (model, operating system, etc.)</li>
                                    <li>App usage analytics</li>
                                </ul>
                            </div>

                        </div>


                        <div className='policy-section mt-2'>
                            <br />
                            {/* <br/> */}
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">2. How We Use Your Information</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">We collect and use your data for the following purposes:</p>
                            <ul>
                                <li>To Manage Your Account: Creating and maintaining your marketer profile</li>
                                <li>To Process Transactions: Tracking sales, referrals, and commission requests</li>
                                <li>To Improve Our Services: Enhancing app performance and user experience</li>
                                <li>To Comply with Legal Obligations: Ensuring regulatory compliance and fraud prevention</li>
                                <li>To Communicate with You: Sending updates about transactions, commissions, or app changes</li>
                            </ul>

                        </div>

                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">3. How We Share Your Information</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">We do not sell or rent your personal data. However, we may share your information in the following cases:</p>
                            <ul>
                                <li>With Legal Authorities: If required by law or to protect our rights</li>
                            </ul>
                        </div>


                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">4. Data Security</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">We implement industry-standard security measures to protect your data from unauthorized access, loss, or misuse. However, no online system is 100% secure, so we encourage users to keep their login details safe.</p>
                        </div>


                        <div className='policy-section mt-2'>
                            <br />
                            {/* <br/> */}
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">5. Your Rights & Choices</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">You have the right to:</p>
                            <ul>
                                <li>Access, update, or delete your personal data</li>
                                <li>Opt-out of non-essential communications</li>
                            </ul>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">To exercise these rights, contact us at support@enterohomes.com.</p>

                        </div>

                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">6. Data Retention</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">We retain your data for as long as necessary to fulfill business or legal purposes. After that, we securely delete or anonymize your data.</p>
                        </div>

                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">7. Updates to This Policy</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">We may update this Privacy Policy from time to time. We will notify you of significant changes via email or app notifications.</p>
                        </div>

                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">8. Contact Us</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">For any questions about this Privacy Policy, contact us at:</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">Entero Homes</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">Email: support@enterohomes.com</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">Phone: +2347040005553</p>
                        </div>


                        <div className='policy-section mt-2'>
                            <br />
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">This Privacy Policy is designed to comply with applicable data protection laws, including GDPR and CCPA, where relevant.</p>
                        </div>




                    </div>
                </div>


            </section>




        </Layout>

    );
};

export default TermsConditions;