import React from 'react';

import { Link } from "react-router-dom";
import Layout from '../Layout';

const TermsConditions = () => {
    return (

        <Layout>


            <section className="abtSecOne">
                <div className="subOne">
                    <h3 className='wow fadeInUp' data-wow-duration="0.8s">How do i close</h3>
                    <h3 className='posTwo wow fadeInUp' data-wow-duration="1.2s">close my Account.</h3>
                </div>
                <div className="subTwo">

                </div>

            </section>


            <section className="abtSecTwo">

                <div className='secTwoDivOne'>
                    <span></span>
                </div>
                <div className='secTwoDivTwo pr-5'>

                    <div>
                        <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">At Entero Homes, we respect your privacy and provide you with the option to delete your account and associated data. If you wish to delete your account, please follow the steps below:</p>

                        <div className='policy-section'>
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">How to Delete Your Account</p>

                            {/* <div className='policy-section-sub'>
                                <p className="policyHeadSub wow fadeInUp" data-wow-duration="1.2s">1. Personal Information</p>
                            </div> */}

                            <div className='policy-section-sub'>
                                <ul>
                                    <li>Sign in to your account on the app.</li>
                                    <li>Navigate to Profile.</li>
                                    <li>Tap on Settings.</li>
                                    <li>Select Delete Account and follow the on-screen instructions to confirm your request.</li>
                                </ul>
                            </div>

                            <div className='policy-section-sub'>
                                <p className="policyHeadSub wow fadeInUp" data-wow-duration="1.2s">What Happens When You Delete Your Account?</p>
                                <ul>
                                    <li>All personal data linked to your account will be permanently removed.</li>
                                    <li>Any pending transactions or commissions will be forfeited.</li>
                                    <li>This action cannot be undone, and your account cannot be recovered after deletion.</li>
                                </ul>
                            </div>


                        <div className='policy-section mt-2'>
                            <br />
                            <p className="policyHeader wow fadeInUp" data-wow-duration="1.2s">Need Assistance?</p>
                            <p className="paragraph-title wow fadeInUp" data-wow-duration="1.2s">If you experience any issues or have questions regarding account deletion, please contact our support team at support@enterohomes.com.</p>
                        </div>

                        </div>




                    </div>
                </div>


            </section>




        </Layout>

    );
};

export default TermsConditions;